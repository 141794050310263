import { useState, useEffect } from 'react';
import { supabase } from '../client/client';
import './EquipmentForm.css';
import Layout from './Layout';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NewsForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        question: '',
    });

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const { data, error } = await supabase.from('questions').select('*').eq('id', id).single();

                if (error) {
                    console.error('Error fetching product data:', error);
                } else {
                    setFormData(data);
                }
            } catch (error) {
                console.error('Error fetching product data:', error.message);
            }
        };

        if (id) {
            fetchProductData();
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleBodyChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            question: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const now = new Date().toISOString();

        try {
            let response;
            if (id) {
                response = await supabase.from('questions').update({
                    ...formData,
                }).eq('id', id);
            } else {
                response = await supabase.from('questions').insert({
                    created_at: now,
                    ...formData,
                });
            }

            if (response.error) {
                console.error('Error al insertar/actualizar en la base de datos:', response.error.message);
            } else {
                console.log('Datos insertados/actualizados exitosamente:', response.data);
                setShowSuccessDialog(true);
            }
        } catch (error) {
            console.error('Error al insertar/actualizar en la base de datos:', error.message);
        }
    };

    const handleAccept = () => {
        setShowSuccessDialog(false);
        navigate("/categorias");
    };

    const handleBack = () => {
        navigate("/categorias");
    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image', 'blockquote', 'code-block'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike',
        'link', 'image', 'blockquote', 'code-block', 'align', 'color', 'background'
    ];


    return (
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="">
                    <div className="border-b border-gray pb-12">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <div>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                        <div className="col-span-full border-t border-gray pt-6 border-t-1">
                                            <label className="block text-base font-semibold leading-6 text-green">Pregunta</label>
                                            <div className="mt-2">
                                                <ReactQuill
                                                    value={formData.question}
                                                    onChange={handleBodyChange}
                                                    modules={modules}
                                                    formats={formats}
                                                    className="bg-gray-50 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" onClick={handleBack} className="text-base font-semibold leading-6 text-green">Cancel</button>
                    <button type="submit" className="rounded-md bg-green px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">Save</button>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    );
};

export default NewsForm;
