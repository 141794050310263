import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataGrid from "react-data-grid";
import "jspdf-autotable";
import { supabase } from "../client/client";
import "react-data-grid/lib/styles.css";
import { useLogin } from "../context/LoginContext";


const FetchPublicity = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState({
        image_url: "",
        number: "",
        empresa: "",
        created_at: "",
    });

    const { getSessionAuth } = useLogin();
    const navigate = useNavigate();

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                await fetchData();
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, [getSessionAuth, navigate]);

    const handleImageClick = (row) => {
        navigate(`/informacion/${row.id}`);
    };

    const columnsPublicity = [
        {
            field: "image_url",
            name: "Foto",
            key: "image_url",
            width: 30,
            renderCell: ({ row }) => (
                <Link to={`/informacion/${row.id}`} onClick={() => handleImageClick(row)}>
                    <img className="" src={row.image_url} alt={row.title} />
                </Link>
            ),
        },
        { field: "number", name: "Número", width: 300, key: "number" },
        { field: "empresa", name: "Empresa", width: 300, key: "empresa" },
        {
            field: "created_at",
            name: "Fecha de ingreso",
            width: 250,
            key: "created_at",
            renderCell: ({ row }) => {
                const formattedDate = new Date(row.created_at).toLocaleDateString();
                return formattedDate;
            },
        },
    ];

    const gridStyle = {
        minHeight: '500px',
    };

    const fetchData = async () => {
        try {
            const { data: publicityData, error: publicityError } = await supabase
                .from("publicity")
                .select("*");
            if (publicityError) {
                throw publicityError;
            }
            setData(publicityData);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        const applyFilters = () => {
            const filtered = data.filter((row) => {
                const rowDate = new Date(row.created_at).toISOString().slice(0, 10);

                return (
                    (filters.number === "" || row.number === Number(filters.number)) &&
                    (filters.empresa === "" || row.empresa?.includes(filters.empresa)) &&
                    (filters.created_at === "" || rowDate === filters.created_at)
                );
            });
            setFilteredData(filtered);
        };

        applyFilters();
    }, [data, filters]);

    const handleFilterChange = (filterName, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    };

    useEffect(() => {
    }, [data, filteredData]);


    return (
        <>
            <div className="items-center mb-4 mt-5 flex flex-col sm:flex-row sm:gap-6 gap-3">
                <input
                    type="number"
                    placeholder="Filtrar por número"
                    value={filters.number}
                    onChange={(e) => handleFilterChange("number", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por empresa"
                    value={filters.empresa}
                    onChange={(e) => handleFilterChange("empresa", e.target.value)}
                />
                <input
                    type="date"
                    placeholder="Filtrar por fecha realizada"
                    value={filters.created_at}
                    onChange={(e) => handleFilterChange("created_at", e.target.value)}
                />
            </div>
            <DataGrid rows={filteredData} columns={columnsPublicity} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
        </>
    )
}

export default FetchPublicity