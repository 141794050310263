import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataGrid from "react-data-grid";
import "jspdf-autotable";
import { supabase } from "../client/client";
import "react-data-grid/lib/styles.css";
import { useLogin } from "../context/LoginContext";


const FetchAnswers = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState({
        written_by: "",
        answer: "",
        image_url: "",
        question_id: "",
        created_at: "",
    });

    const { getSessionAuth } = useLogin();
    const navigate = useNavigate();

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                await fetchQuestions();
                await fetchData();
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, [getSessionAuth, navigate]);

    const fetchQuestions = async () => {
        try {
            const { data: questionesData, error } = await supabase.from("questions").select("*");
            if (error) {
                console.error("Error fetching categories:", error);
            } else {
                setCategories(questionesData);
            }
        } catch (error) {
            console.error("Error fetching categories:", error.message);
        }
    };

    const handleImageClick = (row) => {
        navigate(`/informacion/${row.id}`);
    };

    const columnsAnswers = [
        {
            field: "image_url",
            name: "Foto",
            key: "image_url",
            width: 30,
            renderCell: ({ row }) => (
                <Link to={`/informacion/${row.id}`} onClick={() => handleImageClick(row)}>
                    <img className="" src={row.image_url} alt={row.title} />
                </Link>
            ),
        },
        // { field: "number", name: "Número", width: 220, key: "number" },
        {
            field: "answer",
            name: "Respuesta",
            width: 300,
            key: "answer",
            renderCell: ({ row }) => {
                return (
                    <Link to={`/categorias/${row.id}/editar`} underline="always">
                        <div
                            dangerouslySetInnerHTML={{ __html: row.answer }}
                            className="quill-text"
                        />
                    </Link>
                );
            },
        },
        { field: "written_by", name: "Escrito por", width: 300, key: "written_by" },
        {
            field: "created_at",
            name: "Fecha de ingreso",
            width: 250,
            key: "created_at",
            renderCell: ({ row }) => {
                const formattedDate = new Date(row.created_at).toLocaleDateString();
                return formattedDate;
            },
        },
    ];

    const gridStyle = {
        minHeight: '500px',
    };

    const fetchData = async () => {
        try {
            const { data: answersData, error: answersError } = await supabase
                .from("answers")
                .select("*");
            if (answersError) {
                throw answersError;
            }

            const questionsIds = answersData.map((answers) => answers.question_id);

            const { data: formularioData, error: formularioError } = await supabase
                .from("questions")
                .select("id, question")
                .in("id", questionsIds);
            if (formularioError) {
                throw formularioError;
            }

            const combinedData = answersData.map((answersItem) => {
                const question = formularioData.find(
                    (que) => que.id === answersItem.question_id
                );
                return {
                    ...answersItem,
                    question_name: question ? question.name : "Sin pregunta",
                };
            });

            setData(combinedData);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };



    useEffect(() => {
        const applyFilters = () => {
            const filtered = data.filter((row) => {
                const rowDate = new Date(row.created_at).toISOString().slice(0, 10);

                return (
                    (filters.written_by === "" || row.written_by?.includes(filters.written_by)) &&
                    (filters.answer === "" || row.answer?.includes(filters.answer)) &&
                    (filters.created_at === "" || rowDate === filters.created_at)
                );
            });
            setFilteredData(filtered);
        };

        applyFilters();
    }, [data, filters]);



    const handleFilterChange = (filterName, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    };

    useEffect(() => {
    }, [data, filteredData]);



    return (
        <>
            <div className="items-center mb-4 mt-5 flex flex-col sm:flex-row sm:gap-6 gap-3">
                <input
                    type="text"
                    placeholder="Filtrar por escritor"
                    value={filters.written_by}
                    onChange={(e) => handleFilterChange("written_by", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por respuesta"
                    value={filters.answer}
                    onChange={(e) => handleFilterChange("answer", e.target.value)}
                />
                <input
                    type="date"
                    placeholder="Filtrar por fecha realizada"
                    value={filters.created_at}
                    onChange={(e) => handleFilterChange("created_at", e.target.value)}
                />
            </div>
            <DataGrid rows={filteredData} columns={columnsAnswers} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
        </>
    )
}

export default FetchAnswers