import { useState, useEffect } from 'react';
import { supabase } from '../client/client';
import './EquipmentForm.css';
import Layout from './Layout';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NewsForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        written_by: '',
        answer: '',
        image_url: '',
    });

    const [questions, setQuestions] = useState([]);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [imageUploadMessage, setImageUploadMessage] = useState('');

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const { data, error } = await supabase.from('answers').select('*').eq('id', id).single();

                if (error) {
                    console.error('Error fetching product data:', error);
                } else {
                    setFormData(data);
                }
            } catch (error) {
                console.error('Error fetching product data:', error.message);
            }
        };

        if (id) {
            fetchProductData();
        }
    }, [id]);

    useEffect(() => {
        const fetchQuestions = async () => {
            const { data, error } = await supabase.from('questions').select('id, question');
            if (error) {
                console.error('Error fetching categories:', error);
            } else {
                setQuestions(data);
            }
        };

        fetchQuestions();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleBodyChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            answer: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.image_url) {
            alert('Por favor, sube una imagen antes de guardar.');
            return;
        }

        const now = new Date().toISOString();

        try {
            let response;
            if (id) {
                response = await supabase.from('answers').update({
                    ...formData,
                }).eq('id', id);
            } else {
                response = await supabase.from('answers').insert({
                    created_at: now,
                    ...formData,
                });
            }

            if (response.error) {
                console.error('Error al insertar/actualizar en la base de datos:', response.error.message);
            } else {
                console.log('Datos insertados/actualizados exitosamente:', response.data);
                setShowSuccessDialog(true);
            }
        } catch (error) {
            console.error('Error al insertar/actualizar en la base de datos:', error.message);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setUploadingImage(true);
        setImageUploadMessage('Cargando imagen...');

        const fileName = `${Date.now()}_${file.name}`;
        try {
            const { data: uploadData, error: uploadError } = await supabase.storage.from('pictures').upload(fileName, file);
            if (uploadError) throw new Error(`Error al subir la imagen: ${uploadError.message}`);

            const { data: publicUrlData, error: publicUrlError } = supabase.storage.from('pictures').getPublicUrl(fileName);
            if (publicUrlError || !publicUrlData.publicUrl) throw new Error(`Error al obtener la URL pública: ${publicUrlError?.message || "URL pública no generada"}`);

            setFormData((prevFormData) => ({
                ...prevFormData,
                image_url: publicUrlData.publicUrl,
            }));
            setImageUploadMessage('Imagen subida correctamente');
        } catch (error) {
            console.error('Error durante la subida o generación del enlace público:', error.message);
            setImageUploadMessage('Error al subir la imagen');
        } finally {
            setUploadingImage(false);
        }
    };

    const handleAccept = () => {
        setShowSuccessDialog(false);
        navigate("/categorias");
    };

    const handleBack = () => {
        navigate("/categorias");
    };

    const stripHTML = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };


    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image', 'blockquote', 'code-block'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike',
        'link', 'image', 'blockquote', 'code-block', 'align', 'color', 'background'
    ];



    return (
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="">
                    <div className="border-b border-gray pb-12">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <div>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label className="block text-base font-semibold leading-6 text-green">Escrito por</label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="written_by"
                                                    id="written_by"
                                                    autoComplete='number'
                                                    className="bg-gray-50 block w-full rounded-md border-0 py-1.5 
                                                text-black shadow-sm ring-1 ring-inset ring-gray-300 
                                                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green sm:text-sm sm:leading-6 p-5"
                                                    value={formData.written_by}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label className="block text-base font-semibold leading-6 text-green">Pregunta</label>
                                            <div className="mt-2">
                                                <select
                                                    id="question_id"
                                                    name="question_id"
                                                    className="bg-gray-50 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green sm:max-w-xs sm:text-sm sm:leading-6 p-5"
                                                    value={formData.question_id}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Seleccione la pregunta</option>
                                                    {questions.map((question) => (
                                                        <option key={question.id} value={question.id}>
                                                            {stripHTML(question.question)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-span-full border-t border-gray pt-6 border-t-1">
                                <label className="block text-base font-semibold leading-6 text-green">Respuesta</label>
                                <div className="mt-2">
                                    <ReactQuill
                                        value={formData.answer}
                                        onChange={handleBodyChange}
                                        modules={modules}
                                        formats={formats}
                                        className="bg-gray-50 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label className="block text-base font-semibold leading-6 text-green">Subir imagen</label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-green/25 px-6 py-10">
                                    <div className="text-center">
                                        {formData.image_url ? (
                                            <div>
                                                <img
                                                    src={formData.image_url}
                                                    alt="Previsualización de la imagen subida"
                                                    className="mx-auto h-48 w-48 object-cover rounded-md"
                                                />
                                                <div className="mt-4">
                                                    <button
                                                        className="text-green font-semibold hover:text-lime-600"
                                                        onClick={() => setFormData((prev) => ({ ...prev, image_url: '' }))}
                                                    >
                                                        Cambiar imagen
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                                    <path d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" />
                                                </svg>
                                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                    <label className="relative cursor-pointer rounded-md bg-white font-semibold text-green focus-within:outline-none focus-within:ring-2 focus-within:ring-lime-600 focus-within:ring-offset-2 hover:text-lime-600">
                                                        <span>Sube un archivo</span>
                                                        <input id="image_url" name="image_url" type="file" className="sr-only" onChange={handleFileChange} />
                                                    </label>
                                                    <p className="pl-1">o arrástralo o suéltalo</p>
                                                </div>
                                                {uploadingImage && <p className="text-gray-500 mt-2">{imageUploadMessage}</p>}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" onClick={handleBack} className="text-base font-semibold leading-6 text-green">Cancel</button>
                    <button type="submit" className="rounded-md bg-green px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">Save</button>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    );
};

export default NewsForm;
