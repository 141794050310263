import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataGrid from "react-data-grid";
import "jspdf-autotable";
import { supabase } from "../client/client";
import "react-data-grid/lib/styles.css";
import { useLogin } from "../context/LoginContext";


const FetchQuestions = () => {
    const { id } = useParams();
    const [first, setfirst] = useState(false)
    const [second, setsecond] = useState(false)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState({
        question: "",
        created_at: "",
    });

    const { getSessionAuth } = useLogin();
    const navigate = useNavigate();

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                await fetchData();
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, [getSessionAuth, navigate]);

    const columnsQuestions = [
        { 
            field: "question", 
            name: "Preguntas", 
            width: 250, 
            key: "question",
            renderCell: ({ row }) => (
                <div
                    dangerouslySetInnerHTML={{ __html: row.question }}
                    className="quill-text"
                />
            )
        },
        {
            field: "created_at",
            name: "Fecha de ingreso",
            width: 250,
            key: "created_at",
            renderCell: ({ row }) => {
                const formattedDate = new Date(row.created_at).toLocaleDateString();
                return formattedDate;
            },
        },
    ];

    const gridStyle = {
        minHeight: '500px',
    };

    const fetchData = async () => {
        try {
            const { data: questionsData, error: questionsError } = await supabase
                .from("questions")
                .select("*");
            if (questionsError) {
                throw questionsError;
            }
            setData(questionsData);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        const applyFilters = () => {
            const filtered = data.filter((row) => {
                const rowDate = new Date(row.created_at).toISOString().slice(0, 10);

                return (
                    (filters.question === "" || row.question?.includes(filters.question)) &&
                    (filters.created_at === "" || rowDate === filters.created_at)
                );
            });
            setFilteredData(filtered);
        };

        applyFilters();
    }, [data, filters]);

    const handleFilterChange = (filterName, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    };

    useEffect(() => {
    }, [data, filteredData]);



    return (
        <>
            <div className="items-center mb-4 mt-5 flex flex-col sm:flex-row sm:gap-6 gap-3">
                <input
                    type="text"
                    placeholder="Filtrar por pregunta"
                    value={filters.question}
                    onChange={(e) => handleFilterChange("question", e.target.value)}
                />
                <input
                    type="date"
                    placeholder="Filtrar por fecha realizada"
                    value={filters.created_at}
                    onChange={(e) => handleFilterChange("created_at", e.target.value)}
                />
            </div>

            <DataGrid rows={filteredData} columns={columnsQuestions} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
        </>
    )
}

export default FetchQuestions