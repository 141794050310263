import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../context/LoginContext";
import { supabase } from "../client/client";

const NewNavbar = () => {
  const [userData, setUserData] = useState(null);
  const { getSessionAuth } = useLogin();
  const [openSearch, setOpenSearch] = useState(false);
  const [menuResp, setMenuResp] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        await getUser(session.id);
      }
    };
    sessionAuth();
  }, []);

  const getUser = async (id) => {
    const { error, data } = await supabase
      .from("users")
      .select()
      .eq(`id`, id)
      .single();
    if (error) throw error.message;
    setUserData(data);
  };

  const openSearchIcon = () => {
    setOpenSearch(true);
  };

  const closeSearchIcon = () => {
    setOpenSearch(false);
  };

  const menuResponsive = () => {
    setMenuResp(!menuResp);
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
    }
  };

  const closeMenu = () => {
    setMenuResp(!menuResp);
  };

  const handleSearch = async () => {
    console.log("Realizar búsqueda con:", searchTerm);
    navigate(`/busqueda/${searchTerm}`);
  };

  return (
    <nav className="bg-primary w-full">
      <div className="flex items-center justify-between w-full mx-auto p-4">
        <Link
          to="/home"
          className="flex rtl:space-x-reverse"
        >
          <img src="/logo2.webp" className="w-[180px]" alt="Flowbite Logo" />
        </Link>
        <div className="flex md:order-2 md:space-x-0 rtl:space-x-reverse">
          <button
            className="bg-white px-4 py-2 text-xs text-center text-black rounded-lg hover:bg-[#058237] hover:text-white transition-colors duration-200"
            onClick={menuResponsive}
          >
            Menú
          </button>
        </div>
        {menuResp && (
          <div className="menu-lateral fadeIn w-60">

            <div className="close-menu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#0a8616"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-6 h-6"
                onClick={closeMenu}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div className="opt-menu-lateral">
              <div className="mt-4">
                <>
                  <button
                    type="button"
                    onClick={handleSignOut}
                    className="flex"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="black"
                      className="w-6 h-6 mt-2 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                    <h3 className="mt-2 flex text-sm" color="#000000">
                      Salir
                    </h3>
                  </button>
                </>
              </div>
            </div>
          </div>
        )}
        <div
          className="justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 md:space-x-8 rtl:space-x-reverse md:flex-row ">
            <li>
              <Link to="/home">
                <button className="bg-white text-xs text-black rounded-lg hover:bg-[#058237] hover:text-white transition-colors duration-200 px-4 py-2">
                  Inicio
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NewNavbar;
