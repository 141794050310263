import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import DataGrid from "react-data-grid";
import JsBarcode from "jsbarcode";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { supabase } from "../client/client";
import { useLogin } from "../context/LoginContext";
import Layout from "./Layout";
import Loading from "./Loading";
import DownloadIcon from "./DownloadIcon";
import CreateIcon from "./CreateIcon";
import "react-data-grid/lib/styles.css";
import FetchQuestions from "./FetchQuestions";
import FetchAnswers from "./FetchAnswers";
import FetchPublicity from "./FetchPublicity";

const ProductsPage = () => {
  const { id } = useParams();
  const [first, setfirst] = useState(false)
  const [second, setsecond] = useState(false)
  const [third, setthird] = useState(false)
  const [fourth, setfourth] = useState(false)
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({
    number: "",
    title: "",
    written_by: "",
    category_id: "",
    created_at: "",
  });

  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        await fetchCategories();
        await fetchData();
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, [getSessionAuth, navigate]);

  const fetchCategories = async () => {
    try {
      const { data: categoriesData, error } = await supabase.from("categorias").select("*");
      if (error) {
        console.error("Error fetching categories:", error);
      } else {
        setCategories(categoriesData);
      }
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  const handleImageClick = (row) => {
    navigate(`/informacion/${row.id}`);
  };

  const columnsNews = [
    {
      field: "image_url",
      name: "Foto",
      key: "image_url",
      width: 30,
      renderCell: ({ row }) => (
        <Link to={`/informacion/${row.id}`} onClick={() => handleImageClick(row)}>
          <img className="" src={row.image_url} alt={row.title} />
        </Link>
      ),
    },
    { field: "number", name: "Número", width: 100, key: "number" },
    {
      field: "title",
      name: "Título",
      width: 300,
      key: "title",
      renderCell: ({ row }) => {
        return (
          <Link to={`/categorias/${row.id}/editar`} underline="always">
            {row.title}
          </Link>
        );
      },
    },
    { field: "written_by", name: "Escrito por", width: 300, key: "written_by" },
    { field: "category_name", name: "Categoría", width: 250, key: "category_name" },
    {
      field: "created_at",
      name: "Fecha de ingreso",
      width: 250,
      key: "created_at",
      renderCell: ({ row }) => {
          const formattedDate = new Date(row.created_at).toLocaleDateString();
          return formattedDate;
      },
  },
  ];

  const gridStyle = {
    minHeight: '500px',
  };

  const fetchData = async () => {
    try {
      const { data: newsData, error: newsError } = await supabase
        .from("news")
        .select("*");
      if (newsError) {
        throw newsError;
      }

      const categoriesIds = newsData.map((news) => news.category_id);

      const { data: formularioData, error: formularioError } = await supabase
        .from("categorias")
        .select("id, name")
        .in("id", categoriesIds);
      if (formularioError) {
        throw formularioError;
      }

      const combinedData = newsData.map((newsItem) => {
        const category = formularioData.find(
          (cat) => cat.id === newsItem.category_id
        );
        return {
          ...newsItem,
          category_name: category ? category.name : "Sin categoría",
        };
      });

      setData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };



  useEffect(() => {
    const applyFilters = () => {
      const filtered = data.filter((row) => {
        const rowDate = new Date(row.created_at).toISOString().slice(0, 10);

        return (
          (filters.title === "" || row.title?.includes(filters.title)) &&
          (filters.written_by === "" || row.written_by?.includes(filters.written_by)) &&
          (filters.category_id === "" || row.category_id?.includes(filters.category_id)) &&
          (filters.created_at === "" || rowDate === filters.created_at)
        );
      });
      setFilteredData(filtered);
    };

    applyFilters();
  }, [data, filters]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
  };


  const cambiarOpcion = () => {
    setfirst(!first)
    setsecond(false)
    setthird(false)
    setfourth(false)
  }

  const cambiarOpcion2 = () => {
    setsecond(!second)
    setfirst(false)
    setthird(false)
    setfourth(false)
  }

  const cambiarOpcion3 = () => {
    setthird(!third)
    setfirst(false)
    setsecond(false)
    setfourth(false)
  }

  const cambiarOpcion4 = () => {
    setfourth(!fourth)
    setfirst(false)
    setsecond(false)
    setthird(false)
  }

  useEffect(() => {
  }, [data, filteredData]);



  return (
    <Layout>
      {loading && <Loading />}
      <div className="flex items-center justify-between mb-10">
        <h1 className="text-[#005121] font-semibold text-3xl">
          CATEGORÍAS
        </h1>
      </div>
      <div className="flex flex-col sm:flex-row sm:gap-6 gap-3">
        <button
          onClick={() => cambiarOpcion()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          CATEGORÍAS
        </button>
        <button
          onClick={() => cambiarOpcion2()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          PREGUNTAS ASOCIADOS
        </button>
        <button
          onClick={() => cambiarOpcion3()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          RESPUESTAS ASOCIADOS
        </button>
        <button
          onClick={() => cambiarOpcion4()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          IMÁGENES PUBLICIDAD
        </button>
      </div>

      <section id="options">
        {first && (
          <>

            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to="/formulario-noticias" className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar Noticia
              </Link>
            </div>
            <div className="items-center mb-4 mt-5 flex flex-col sm:flex-row sm:gap-6 gap-3">
              <input
                type="text"
                placeholder="Filtrar por título"
                value={filters.title}
                onChange={(e) => handleFilterChange("title", e.target.value)}
              />
              <input
                type="text"
                placeholder="Filtrar por autor"
                value={filters.written_by}
                onChange={(e) => handleFilterChange("written_by", e.target.value)}
              />
              <input
                type="text"
                placeholder="Filtrar por categoría"
                value={filters.category_name}
                onChange={(e) => handleFilterChange("category_name", e.target.value)}
              />
              <input
                    type="date"
                    placeholder="Filtrar por fecha realizada"
                    value={filters.created_at}
                    onChange={(e) => handleFilterChange("created_at", e.target.value)}
                />
            </div>
            <DataGrid rows={filteredData} columns={columnsNews} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
          </>
        )}
        {second && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to="/preguntas/crear" className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar Pregunta
              </Link>
            </div>
            <FetchQuestions />
          </>
        )}
        {third
         && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to="/respuestas/crear" className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar Respuesta
              </Link>
            </div>
            <FetchAnswers />
          </>
        )}
        {fourth && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to="/publicidad/crear" className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar Publicidad
              </Link>
            </div>
            <FetchPublicity />
          </>
        )}
      </section>
    </Layout>
  );
};

export default ProductsPage;
